import { reuqestInsertInvitation,reuqestInsertInvitationAttend,reuqestListInvitationAttend,reuqestUpdateInvitation,requestInvitationList, requestExternalInvitation,requestInvitation,requestInvitationDeleteWatermark,requestInvitationviewUrl,requestInvitationviewKakao 
,reuqestInsertInvitationGuestBook,reuqestDeleteInvitationGuestBook,reuqestListInvitationGuestBook


} from '@/api/invitation'

import store from '@/store'
import router from '@/router'
import EventBus from "@/utils/CommonEventBus";


export const invitation = {
    namaspaced: true,
  
    state: () => ({
        invitationList:[],
        invitationAttendList:[],
        invitationAttendStatic:{
            responseCnt:'',
            gooomPossibleCnt:'',
            bridePossibleCnt:'',
            gooomImpossibleCnt:'',
            brideImpossibleCnt:'',
            eatCnt:'',
            noEatCnt:''

        },
        invitationGuestBookList:[]
    }),
  
    getters: {
        getInvitationList(state){
            return state.invitationList
        },
        getInvitationAttendList(state){
            return state.invitationAttendList
        },
        getInvitationAttendStatic(state){
            return state.invitationAttendStatic
        },
        getInvitationGuestBookList(state){
            return state.invitationGuestBookList
        }
    },
  
    mutations: {
        REQUEST_INVITATION_LIST(state, data) {
            state.invitationList = data;
        },
        REQUEST_INVITATION_LIST_INIT(state, data) {
            state.invitationList = [];
        },
        REQUEST_LIST_INVITATION_ATTEND(state,data){
            state.invitationAttendList = data.invitationAttendList;
            state.invitationAttendStatic = data.invitationAttendStatic

        },
        REQUEST_LIST_INVITATION_ATTEND_INIT(state){
            state.invitationAttendList = [];
            state.invitationAttendStatic = {
                responseCnt:'',
                gooomPossibleCnt:'',
                bridePossibleCnt:'',
                gooomImpossibleCnt:'',
                brideImpossibleCnt:'',
                eatCnt:'',
                noEatCnt:''
            
            };

        },
        REQUEST_LIST_INVITATION_GUEST_BOOK(state,data){
            
            state.invitationGuestBookList = data;
        },
        REQUEST_LIST_INVITATION_GUEST_BOOK_INIT(state){
            state.invitationGuestBookList = [];
        },
    },
  
    actions: {
        async REQUEST_INSERT_INVITATION(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await reuqestInsertInvitation(parameter);
                
                if (response.result === 'succ') {
                    router.replace('/');
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INSERT_INVITATION_ATTEND(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await reuqestInsertInvitationAttend(parameter.invitationAttend);
                
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    
                    EventBus.$emit('popupClose')
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_LIST_INVITATION_ATTEND(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await reuqestListInvitationAttend(parameter.invitationAttend);
                
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    context.commit('REQUEST_LIST_INVITATION_ATTEND', response.data);
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
        },

        
        
        async REQUEST_UPDATE_INVITATION(context,parameter){
            context.commit('START_LOADING');
            try{
                
                const response = await reuqestUpdateInvitation(parameter);
                
                if (response.result === 'succ') {
                    router.replace('/');
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');

        },
        async REQUEST_INVITATION_SELECT(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestInvitation(parameter);
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    param.data = response.data
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
            return param

        },
        async REQUEST_EXTENAL_INVITATION_SELECT(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestExternalInvitation(parameter);
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    param.data = response.data
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
            return param

        },
        
        async REQUEST_INVITATION_LIST(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInvitationList(parameter);
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    context.commit('REQUEST_INVITATION_LIST', response.data);
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
            

        },
        async REQUEST_INVITATION_DELETE_WATERMARK(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                
                const response = await requestInvitationDeleteWatermark(parameter);
                
                if (response.result === 'succ') {
                    param.result = true
                }else{
                    
                    param.result = false
                }
            }catch(e){
                param.result = false
                console.log(e)  
            }
            context.commit('END_LOADING');
            return param

        },
        
        async REQUEST_SELECT_INVITATIONVIEW_URL(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestInvitationviewUrl(parameter.invitation);
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    param.data = response.data
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
            return param

        },
        async REQUEST_SELECT_INVITATIONVIEW_KAKAO(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestInvitationviewKakao(parameter.invitation);
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    param.data = response.data
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
            return param

        },
        
        
        
        async REQUEST_INSERT_INVITATION_GUEST_BOOK(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await reuqestInsertInvitationGuestBook(parameter.invitationGuestBook);
                
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    
                    store.dispatch('REQUEST_LIST_INVITATION_GUEST_BOOK',parameter)
                    EventBus.$emit('popupClose')
                }
            }catch(e){
                console.log(e)  
            }
        },
        async REQUEST_DELETE_INVITATION_GUEST_BOOK(context,parameter) {
            context.commit('START_LOADING');
            debugger
            try{
                const response = await reuqestDeleteInvitationGuestBook(parameter.invitationGuestBook);
                
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    store.dispatch('REQUEST_LIST_INVITATION_GUEST_BOOK',parameter)
                    EventBus.$emit('popupClose')
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_LIST_INVITATION_GUEST_BOOK(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await reuqestListInvitationGuestBook(parameter.invitationGuestBook);
                
                if (response.data !== null && response.data != undefined && response.result === 'succ') {
                    context.commit('REQUEST_LIST_INVITATION_GUEST_BOOK', response.data);
                    
                    setTimeout(function() { parameter.context.subScript.swiperFn(); }, 100);
                }
            }catch(e){
                console.log(e)  
            }
            context.commit('END_LOADING');
        },

        
        
        
    }
  }
