/* vue */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* element-ui */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

/* mixin */
import Common from '@/mixin/Common.vue'

/* style */
import '@/style/index.scss'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import vmodal from 'vue-js-modal'
import VueSpinners from 'vue-spinners'

/* permission */
import '@/navigationguards/permission' // permission control



import 'vue-advanced-cropper/dist/style.css';
import 'swiper/dist/css/swiper.css'

import VueClipboard from 'vue-clipboard2'


Vue.config.productionTip = 
Vue.use(vmodal)
Vue.use(VueSpinners)
Vue.mixin(Common)
Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
