import { requestAllCode } from '@/api/code'



export const code = {
    namaspaced: true,
  
    state: () => ({
        resultAllCodeList: [],
    }),
  
    getters: {
        getAllCodeList(state) {
            return state.resultAllCodeList;
        },
    },
  
    mutations: {
        CODE_ALL_LIST(state, data) {
            state.resultAllCodeList = data;
        },
        CODE_ALL_LIST_INIT(state) {
            state.resultAllCodeList = [];
        }
    },
  
    actions: {
        async request_all_code_list(context) {
            let result = false;
            try {
                
                const response = await requestAllCode();
                if (response.data !== null && response.data != undefined && response.result === 'success') {
                    result = true;
                    context.commit('CODE_ALL_LIST', response.data);
                } else {
                    alert(response.data.message);
                }
                return response;
            } catch (e) {
                // alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
            }
            return response;
        },
    }
  }
