import axios from 'axios'
import store from '@/store'
import { getToken, getRefreshToken, removeAllToken,getNaverToken,getNaverRefreshToken,getNaverTokenType } from '@/utils/auth'


import { Message } from 'element-ui'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 * 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  async config =>  {

    
    // do something before request is sent
    if (config.url.indexOf('/auth/reissue') < 0 ) {
      console.log('token',store.getters.getToken)
      const token = await getToken();
      if (token!==null && token!==undefined) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['Authorization'] = token
        console.log(config.headers['Authorization'])
      }
    }

    if(config.url.indexOf('/auth/naver') !== -1 ){
        if(config.data === undefined || typeof config.data==='string'){
          config.data = {}
        }
        config.data.access_token = getNaverToken();
        config.data.refresh_token = getNaverRefreshToken();
        config.data.token_type = getNaverTokenType();
        config.data.clientId = process.env.VUE_APP_NAVER_CLINET_ID
        config.data.clientSecret =process.env.VUE_APP_NAVER_CLIENT_SECRET
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async response => {
    
    
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.responseCode !== undefined && res.result === 'fail') {
      if(res.message === '만료된 토큰입니다.'){

        removeAllToken();
        router.push('/login');
      }else{
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
    }

    if (res.responseCode !== undefined && res.result === 'succ' && res.message !==null && res.message !==undefined) {
      Message({
        message: res.message ,
        type: 'success',
        duration: 5 * 1000
      })
    }

    if (res.responseCode !== undefined && res.responseCode.code === 30000) {
      store.dispatch('user/resetToken').then(() => {
        router.push('/login')
      })
    }


    if (res.responseCode !== undefined && res.responseCode.code != 20000) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code.code === 50008 || res.code.code === 50012 || res.code.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       router.push('/login')
      //     })
      //   })
      // }

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  async error => {
    // console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // return Promise.reject(error)

    try {
    
      //에러에 대한 response 정보
      const errorAPI = error.response.config; //요청했던 request 정보가 담겨있음
      //인증에러 및 재요청이 아닐 경우... (+재요청인데 refreshToken이 있을 경우)

      //네이버리프레시accesstoken
    if(errorAPI.url.indexOf('/auth/naverProfile')!==-1){
      
      if(error.response.data.message.indexOf('401 Unauthorized')>-1){
        await store.dispatch('REQUEST_NAVER_RFRESH_TOKEN'); //로그인 중간 저장소에 있는 토큰 재발급 action을 실행
        return await service.request(errorAPI); //다시 axios 요청
      }

    }

    if(errorAPI.url.indexOf('/auth/naverLogout')!==-1){
      
      removeAllToken();
      router.push('/login');
      return Promise.reject(error);

    }


    
    if (error.response.status == 401 && errorAPI.retry == undefined) {
      
        var refreshToken = await getRefreshToken();
        if(refreshToken!==null){
          const parameter = {
            refreshToken: refreshToken
          }
          await store.dispatch('REQUEST_REFRESH_TOKEN', parameter); //로그인 중간 저장소에 있는 토큰 재발급 action을 실행
          errorAPI.retry = true; //재요청이라고 추가 정보를 담음
          return await service.request(errorAPI); //다시 axios 요청

        }
      }

      
    } catch (err) {
      console.error('[axios.interceptors.response] error : ', err.message);
    }
    return Promise.reject(error);
  }


)

export default service
