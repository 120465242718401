<template>
  <div id="app">
    <transition  name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
  computed: {
  },
}
</script>

<style></style>
