import Cookies from 'js-cookie'
const TokenKey = 'accessToken'
const RefreshToken = 'refreshToken'


const NaverToken = 'naverToken'
const NaverRefreshToken = 'naverRefreshToken'
const NaverTokenType = 'naverTokenType'
const NaverExpiresIn = 'naverExpiresIn'

export async function getToken() {
  return Cookies.get(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(RefreshToken)

}


export function getNaverToken() {
  return Cookies.get(NaverToken)

}
export function getNaverRefreshToken() {
  return Cookies.get(NaverRefreshToken)

}
export function getNaverTokenType() {
  return Cookies.get(NaverTokenType)

}
export function getNaverExpiresIn() {
  return Cookies.get(NaverExpiresIn)

}

export function setToken(token) {
  Cookies.set(TokenKey, "Bearer " + token.accessToken)
  Cookies.set(RefreshToken, token.refreshToken)
}

export function setNaverToken(data){
  
  Cookies.set(NaverToken, data.access_token)
  Cookies.set(NaverRefreshToken, data.refresh_token)
  Cookies.set(NaverTokenType, data.token_type)
  Cookies.set(NaverExpiresIn, data.expires_in)

}

export function removeAllToken() {
  Cookies.remove(TokenKey)
  Cookies.remove(RefreshToken)

  
  Cookies.remove(NaverToken)
  Cookies.remove(NaverRefreshToken)
  Cookies.remove(NaverTokenType)
  Cookies.remove(NaverExpiresIn)
  return
}

export function removeAccessToken() {
  Cookies.remove(TokenKey)
  return
}
