import { requestLogin, requestNaverLogin,requestNaverLogout,requestNaverRefreshToken,requestNaverProfile,logout, getInfo, signup, resetToken, update, reissueToken } from '@/api/myUser'
import { getNaverToken, getNaverRefreshToken, getNaverTokenType, getNaverExpiresIn,getToken, setToken,setNaverToken, removeAllToken, getRefreshToken, removeAccessToken } from '@/utils/auth'

import router from '@/router'
import store from '@/store'




export const user = {
  namaspaced: true,

  state: () => ({
    token: getToken(),
    refreshToken: getRefreshToken(),

    
    naverToken:getNaverToken(),
    naverRefreshToken:getNaverRefreshToken(),
    naverTokenType:getNaverTokenType(),
    naverExpiresIn:getNaverExpiresIn(),

    user:{
      email: null,
      phone: null,
      name: null,
      nickname: null,
      profileImage: null,
      userAuthority:null,
    }

  }),

  getters: {
    getUserInfo(state){
      return state.user
    },
    getToken(state){
      return state.token
    },
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAVER_TOKEN: (state,data) => {
      state.naverToken = data.access_token
      state.naverRefreshToken = data.refresh_token
      state.naverTokenType = data.token_type
      state.naverExpiresIn = data.expires_in
    },
    SET_USER: (state, userObj) => {
      state.user.email = userObj.email
      state.user.phone = userObj.phone
      state.user.name = userObj.name
      state.user.nickname = userObj.nickname
      state.user.profileImage = userObj.profileImage
      state.user.userAuthority = userObj.userAuthority
    },
    SET_NAVER_USER: (state, userObj) => {
      state.user.email = userObj.email
      state.user.phone = userObj.phone
      state.user.name = userObj.name
      state.user.nickname = userObj.nickname
      state.user.profileImage = userObj.profile_image
      state.user.userAuthority = userObj.userAuthority
    }
  },

  actions: {
    async REQUEST_LOGIN(context, parameter) {
      context.commit('START_LOADING');
      
      const param = {
        email: parameter.id,
        name: parameter.name,
        nickname: parameter.nickname,
        profileImage: parameter.profile_image
      }
      try{
          const response = await requestLogin(param);
          
          console.log(response)
          const { data } = response
          context.commit('SET_USER', data.userDto)
          context.commit('SET_TOKEN', data.tokenDto.accessToken)
          setToken(data.tokenDto)
          router.push('/');
      }catch(e){
          console.log(e)  
      }
      context.commit('END_LOADING');
    },
    async REQUEST_LOGOUT(context, parameter) {
      context.commit('START_LOADING');
      try{
        

        
        const response = await requestNaverLogout();
        if(response.data.resultcode === '00'){
          alert('suceess')
        }

          removeAllToken()
          router.push('/login');
      }catch(e){
          console.log(e)  
      }
      context.commit('END_LOADING');
    },
    async REQUEST_NAVER_LOGIN(context, parameter) {
      context.commit('START_LOADING');
      try{
        const response = await requestNaverLogin(parameter);
        context.commit('SET_NAVER_TOKEN', response.data)
        setNaverToken(response.data)
        const param = {
          type:'login'
        }
        store.dispatch('REQUEST_NAVER_PROFILE',param)
          //router.push('/login');
      }catch(e){
        router.replace('/login')
          console.log(e)  
      }
      context.commit('END_LOADING');
    },
    
    async REQUEST_NAVER_PROFILE(context,parameter) {
      context.commit('START_LOADING');
      try{
        const response = await requestNaverProfile();
        if(response.data.resultcode === '00'){
          if(parameter!==null && parameter!==undefined && parameter.type === 'login'){
            store.dispatch('REQUEST_LOGIN',response.data.response)
          }
          store.commit('SET_NAVER_USER',response.data.response)
        }else{
          store.dispatch("REQUEST_LOGOUT")
        }
          //router.push('/login');
      }catch(e){
          console.log(e)  
      }
      context.commit('END_LOADING');
    },
    async REQUEST_NAVER_RFRESH_TOKEN(context) {
      
      try{
        const response = await requestNaverRefreshToken();
        if(response.data.access_token !== null && response.data.access_token !== undefined){
          context.commit('SET_NAVER_TOKEN', response.data)
          setNaverToken(response.data)
        }else{
          store.dispatch("REQUEST_LOGOUT")
        }
          //router.push('/login');
      }catch(e){
        store.dispatch("REQUEST_LOGOUT")
        console.log(e)
      }
    },
    async REQUEST_REFRESH_TOKEN(context, parameter) {
      try{
          const response = await reissueToken(parameter);
          if(response.data.tokenDto.accessToken !== null && response.data.tokenDto.accessToken !== undefined){
            context.commit('SET_TOKEN', response.data.tokenDto.accessToken)
            setToken(response.data.tokenDto)
        }else{
          store.dispatch("REQUEST_LOGOUT")
        }
      }catch(e){
        store.dispatch("REQUEST_LOGOUT")
        console.log(e)  
      }
    },
  }
}
