/* auth 관련 login, 회원가입 등 */

const userAuthRouter = [
  {
    path: '/login',
    component: () => import('@/views/userAuth/Login.vue'),
    name: 'Login',
    meta: { title: '로그인' },
  },
  {
    path: '/auth',
    component: () => import('@/views/userAuth/Auth.vue'),
    name: 'Auth',
    meta: { title: '인증' },
  },
]

export default userAuthRouter
