import request from '@/utils/request'
import Cookies from 'js-cookie';

export function requestLogin(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  })
}

export function requestNaverLogin(data){
  return request({
    url: '/auth/naverLogin',
    method: 'post',
    data: data
  })
}

export function requestNaverLogout(){
  return request({
    url: '/auth/naverLogout',
    method: 'post'
  })
}

export function requestNaverRefreshToken(){
  return request({
    url: '/auth/naverRefreshToken',
    method: 'post'
  })
}



export function requestNaverProfile(){
  
  return request({
    url: '/auth/naverProfile',
    method: 'post'
  })
}

export function getInfo() {
  return request({
    url: '/user/myInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

export function signup(data) {
  return request({
    url: '/auth/signup',
    method: 'post',
    data
  })
}

export function resetToken(data) {
  return request({
    url: '/auth/reissue',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}

export function reissueToken(data) {
  return request({
    url: '/auth/reissue',
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: '/user/myPage/userUpdate',
    method: 'post',
    data
  })
}

export function userDetail(data) {
  return request({
    url: '/userManage/userDetail',
    method: 'post',
    params: {
      email: data
    }
  })
}
