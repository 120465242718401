

import { Loading } from 'element-ui';
import EventBus from "@/utils/CommonEventBus";

export const common = {
    namaspaced: true,

    state: () => ({
        loadingInstance:null,
        previewFlag:false,
        fullScreenFlag: true,
    }),

    getters: {
        getPreviewFlag(state){
            return state.previewFlag
        },
        getFullScreenFlag(state){
            return state.fullScreenFlag
        }
    },

    mutations: {
        START_LOADING(state){
            state.loadingInstance = Loading.service({ fullscreen: true })
        },
        END_LOADING(state){
            state.loadingInstance.close();

        },
        PREVIEW_CLICK(state,data){
            if(data){
                $("body").addClass("stop-scroll");
                $(".preview-area").css("z-index", 20)
            }else{
                $("body").removeClass("stop-scroll");
                $(".preview-area").css("z-index", 0)
            }
        },
        PREVIEW_CLICK_BTN(state,data){
            state.previewFlag = data;
            if(data){
                $("body").addClass("stop-scroll");
                $(".preview-area").css("z-index", 20)
                setTimeout(function() { 
                    EventBus.$emit('changeMarketPosition')
                    $(".wedding-wrap .video .video-div").height($(".wedding-wrap .video .video-div").width() * 0.8)
                }, 1000);
            }else{
                $("body").removeClass("stop-scroll");
                $(".preview-area").css("z-index", 0)
            }
        },
        FULL_SCREEN(state,data){
            state.fullScreenFlag = data
            if(data){
                $("body").removeClass("stop-scroll");
                $(".preview-area").css("max-height", $(window).innerHeight() - ($("header").innerHeight() * 2))
            }else{
            }
        }
    },

    actions: {
    }
}