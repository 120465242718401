import request from '@/utils/request'

export function reuqestInsertInvitation(data){
    return request({ 
      url: '/invitation/insert',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    })
  }
  
export function reuqestInsertInvitationAttend(data){
  return request({ 
    url: '/invitationAttend/insert',
    method: 'post',
    data
  })
}
export function reuqestListInvitationAttend(data){
  return request({ 
    url: '/invitationAttend/list',
    method: 'post',
    data
  })
}

  
  
export function reuqestUpdateInvitation(data){
  return request({ 
    url: '/invitation/update',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
  


  export function requestInvitationList(data) {
    return request({
      url: '/invitation/list',
      method: 'post',
      data,
    })
  }
  export function requestInvitation(data) {
    return request({
      url: '/invitation/select',
      method: 'post',
      data,
    })
  }
  export function requestExternalInvitation(data) {
    return request({
      url: '/external/invitation/select',
      method: 'post',
      data,
    })
  }
  

  export function requestInvitationDeleteWatermark(data) {
    return request({
      url: '/invitation/watermark/delete',
      method: 'post',
      data,
    })
  }

  export function requestInvitationviewUrl(data) {
    return request({
      url: '/invitation/aesinvitationurl/select',
      method: 'post',
      data,
    })
  }

  export function requestInvitationviewKakao(data) {
    return request({
      url: '/invitation/aesinvitationkakao/select',
      method: 'post',
      data,
    })
  }

  
  
  export function reuqestInsertInvitationGuestBook(data) {
    return request({
      url: '/invitationGuestBook/insert',
      method: 'post',
      data,
    })
  }
  export function reuqestDeleteInvitationGuestBook(data) {
    return request({
      url: '/invitationGuestBook/delete',
      method: 'post',
      data,
    })
  }
  export function reuqestListInvitationGuestBook(data) {
    return request({
      url: '/invitationGuestBook/list',
      method: 'post',
      data,
    })
  }
  
  