import Vue from 'vue'
import VueRouter from 'vue-router'
import UserAuth from './modules/userAuth'

Vue.use(VueRouter)

const routes = [
  ...UserAuth,
  
  {
    path: '/invitationview',
    component: () => import('@/views/invitationview/Invitationview.vue'),
    name: 'Invitationview',
    meta: { title: '' },
  },
  

  
  {
    path: '/',
    redirect: '/',
    name: 'Home',
    component: () =>
      import('@/container/TheContainer.vue'),
    children: [
      {
        path: '/adminInvitationModify',
        component: () =>
        import('@/views/write/Write.vue'),
        name: 'adminInvitationModify',
        meta: { title: '' },
      },
      {
        path: '/',
        name: 'MainView',
        component: () =>
        
        import('@/views/mainView/MainView.vue'),
      },
      {
        path: '/write',
        name: 'Write',
        component: () =>
        import('@/views/write/Write.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  base: process.env.NODE_ENV === 'development' ? '' :'',
  mode: 'history'
})

export default router
